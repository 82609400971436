import React from "react"

import { SEO, Section } from "components"

const IndexPage = () => (
  <>
    <SEO title="Home" />

    <Section container>Hello</Section>
  </>
)

export default IndexPage
